.oniichat-plus-panel {
  .termsText{
    font-size: 10px;
    color: #666;
    font-family: moderat;
  }
    .subscribed-message {
        padding: 20px;
        background-color: #e9ecef;
        border-radius: 10px;
        margin: 20px 0;
        
        p {
          margin: 0;
          color: #333;
          font-size: 16px;
          text-align: center;
        }
      }
    .subscription-content {
      background: #fff;
      border-radius: 20px;
      box-shadow: 0 4px 8px rgba(0,0,0,0.1);
      padding: 20px;
      text-align: center;
      width: 100%; // Use 100% width for responsiveness
      max-width: 350px; // Set a max-width for larger screens
      margin: auto;
    }
  
    h2 {
      color: #333;
      font-size: 24px;
      margin-bottom: 15px;
    }
  
    .features {
      list-style: none;
      padding: 0;
      margin-bottom: 20px;
      text-align: left;
  
      li {
        margin-bottom: 10px;
        font-size: 16px;
        color: #333;
  
        &.unavailable {
          color: #bbb;
        }
  
        &:before {
          content: '✓';
          color: #4e73df;
          font-weight: bold;
          margin-right: 5px;
        }
      }
    }
  
    .plan-selection {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;
  
      .plan-option {
        border: 1px solid #4e73df;
        border-radius: 20px;
        padding: 10px 20px;
        cursor: pointer;
        background-color: transparent;
        transition: all 0.3s;
        font-weight: bold;
  
        &:hover {
          background-image: linear-gradient(rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%), linear-gradient(rgb(92, 116, 204) 0%, rgb(33, 52, 122) 100%);
        }

        &.selected {
          background-image: linear-gradient(rgb(255, 162, 109) -9%, rgb(131, 113, 242) 45%, rgb(53, 37, 98) 103%), linear-gradient(rgb(92, 116, 204) 0%, rgb(33, 52, 122) 100%);
          color: white;
          box-shadow: 0px 0px 15px rgba(131, 113, 242, 0.4);
        }
      }
    }
  
    .subscribe-button-container {
      position: relative;
      margin-bottom: 20px;
  
      .subscribe-button {
        background-color: #4e73df;
        color: white;
        border: none;
        border-radius: 20px;
        padding: 15px 20px;
        font-size: 18px;
        cursor: pointer;
        width: 100%;
        font-weight: bold;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: darken(#4e73df, 10%);
        }
      }
  
      .badge {
        position: absolute;
        right: -1px;
        top: -1px;
        background-color: #ff414d;
        color: white;
        border-radius: 20px;
        padding: 5px 10px;
        font-size: 12px;
        transform: translate(25%, -25%);
      }
    }
  
    .cancel-info {
      color: #666;
      font-size: 14px;
      margin-top: 10px;
    }
  }
  