
.reportProblemButton {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 200px;
}

.reportProblemButton:hover {
  color: #0056b3;
}

.payWithCreditCard {
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s;
  width: 100%;
  margin-top: 20px;
}

.payWithCreditCard:hover {
  background-color: #0056b3;
}

.payWithCreditCard:active {
  background-color: #004085;
}

.payWithCreditCard i {
  margin-right: 8px;
}
  // Styles for ProblemReportForm
  .problemReportModal {
    // Add styles for the modal if needed
  }
  
  .problemReportForm {
    // Add styles for the form if needed
  }
  
  .problemReportInput {
    // Add styles for the input fields if needed
  }
  
  .problemReportTextArea {
    // Add styles for the text area if needed
  }
  
  .problemReportButtons {
    // Add styles for the buttons if needed
  }
  
  .problemReportCancelButton {
    // Add styles for the cancel button if needed
  }
  
  .problemReportSubmitButton {
    // Add styles for the submit button if needed
  }  
