/* General styles */
.launcher-body {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    height: auto;
}
.yourWaifu{
    background-image:  linear-gradient(180deg, #5C75CC 0%, #203378);
    height: 100%;
   
}
.launcher-item {
    animation: puff-in-center .3s cubic-bezier(.47,0,.745,.715) both;
    background: var(--gray-50);
    border-radius: 8px;
    min-height: 400px;
    padding: 32px;
    transition: background .1s ease-in-out;
    display: flex; 
    justify-content: center;
    gap: 20px; 
    .startChatButton {
        z-index: 40;
        margin-top: 2rem;
        cursor: pointer;
        position: relative;
        border: none;
        border-radius: 24px;
        align-self: center;
        font-family: PPPangramSansRounded-Bold, moderat;
        line-height: 20px;
        font-size: 20px;
        text-align: center;
        padding: 20px 30px;
        background: linear-gradient(180deg, rgba(224, 224, 224, 0) 0%, #d9d9d9 100%),
          rgba(255, 255, 255, 0.9);
        box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.9),
          inset 0px 0px 5px rgba(255, 255, 255, 0.8);
        color: #5e5e5e;
        opacity: 1;
        -webkit-transition: box-shadow 0.3s ease;
        transition: box-shadow 0.3s ease;
      }
      .startChatButton:hover {
        opacity: 1;
        box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9),
          inset 0px 0px 5px rgba(255, 255, 255, 0.8);
      } /*!sc*/
}

.link-style, a {
    color: #10a37f;
    cursor: pointer;
    text-decoration: none;
}

/* Button styles */
.btn-13 {
    border: none;
    border-radius: 8px; 
    z-index: 1;
    position: relative; 
    color: #fff;  /* Changed from transparent to white */
}


.btn-13:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 8px;
    transition: all 0.3s ease;
    box-shadow:
        -7px -7px 20px 0px #fff9,
        -4px -4px 5px 0px #fff9,
        7px 7px 20px 0px #0002,
        4px 4px 5px 0px #0001;
}

.btn-13:hover {
    color: #fff; /* On hover, make the text white */
}

.btn-13:hover:after {
    top: 0;
    height: 100%;
}

.btn-13:active {
    top: 2px;
}

/* Specific styles for Button 1 */
.btn-13a {
    width: 300px;
    height: 500px; 
    background-image: linear-gradient(315deg, #0d6efd, #5c75cc); /* Gradient from bright blue to a midway blue color */
}

.btn-13a:after {
    background-color: #3e6fda; /* Midway blue color for the :after effect */
    background-image: linear-gradient(315deg, #0d6efd, #5c75cc); /* Gradient from bright blue to a midway blue color for the hover effect */
}

/* Specific styles for Button 2 */
.btn-13b {
    width: 300px;
    height: 500px; 
    background-image: linear-gradient(315deg, #5c75cc, #0d6efd); /* Gradient from the midway blue color to bright blue */
}

.btn-13b:after {
    background-color: #3e6fda; /* Adjusted blue color for the :after effect */
    background-image: linear-gradient(315deg, #5c75cc, #0d6efd); /* Gradient from the midway blue color to bright blue for the hover effect */
}
/* Additional CSS for mobile responsiveness */
@media screen and (max-width: 768px) {
    .launcher-item {
        min-height: 300px;
        flex-direction: column;
    }
    .btn-13 {
        width: 200px;
        height: 300px;
    }
}
/* Enhanced Visual Design */
.launcher-item-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f0f0f0;
    border: 2px dashed #ccc;
    border-radius: 8px;
    height: 500px;
    width: 300px;
    text-align: center;
}

.placeholder-text {
    font-size: 1.2em;
    color: #888;
}

/* Styles for the model image */
.waifu-image {
    border-radius: 50%;  /* Making the image circular */
    border-radius: 50%;
    margin-top: 20px;
    height: 50%;
    width: auto;
    z-index: 1;
}

/* Style for the button label text */
.btn-label {
    z-index: 2;  /* Ensures text stays above the image */
    position: relative;  /* Relative positioning */
}





