
.reward-window {
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(180deg, #5C75CC 0%, #203378 100%);
    .twitterWrapper {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
    }
    
    .clickHereIndicator {
      cursor: pointer;
      margin-left: 5px;
      font-size: 12px;
      color: #1DA1F2; /* Twitter blue, adjust as needed */
    }
  .row{
    margin-top: 1rem;
  }
  .grid{
    width: 100%;
      max-width: 540px;
      padding: 10px 0px 0px;
      margin: 0px;
      list-style: none;
      display: grid;
      gap: 10px;
      @media (min-width: 300px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 600px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 900px) {
        grid-template-columns: repeat(3, 1fr);
    }
  }
  .container{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header{
    color: white;
  }
  span{
    color: white;
  }
  .characterReward{
    position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 14px;
      z-index: 1;
  }
    .rewardItem {
        min-height: 185px;
        display: flex;
        flex-direction: column;
        
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-radius: 19px;
        background: rgba(255, 255, 255, 0.1);
        padding: 10px;
        position: relative;
        color: white;
      input{
        color: black;
      }
      .rewardButton {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        padding: 0px;
        gap: 5px;
        margin: 10px 0px;
        width: 80%;
        height: 34px;
        background: linear-gradient(rgb(255, 255, 255) 0%, rgb(217, 217, 217) 100%);
        box-shadow: rgba(209, 209, 209, 0.9) 0px 0px 15px, rgba(255, 255, 255, 0.4) 0px 0px 5px inset;
        border-radius: 14px;
        border: none;
        cursor: pointer;
        font-size: 16px;
        line-height: 34px;
        color: rgb(94, 94, 94);
    
      }
      .gems {
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        color: rgb(255, 255, 255);
        font-family: PPPangramSansRounded-Bold, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
      }
    }
  }
  .gemItemDaily{
    img {
      margin-top: 0.2rem;
      margin-bottom: 0.1rem;
      width: 120px;
      height: 80px;
      filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 5px 30px);
    }
  }
  .quest{
    img {
      margin-top: 0.2rem;
      margin-bottom: 0.1rem;
      height: 50px;
      filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 5px 30px);
    }
  }
  .dailyRewardTimer
  {
    display: flex;
    align-items: center;
    color: white;
    margin-bottom: 2rem;
    .dailyRewardButton
    {
      margin-left: 1rem;
      img {
        padding-left: 4px;
        max-width: 20px;
        max-height: 20px;
      }
      cursor: pointer;
      border: none;
      border-radius: 24px;
      align-self: center;
      font-family: PPPangramSansRounded-Bold, moderat;
      line-height: 20px;
      font-size: 16px;
      text-align: center;
      padding: 10px 20px;
      background: linear-gradient(
          180deg,
          rgba(224, 224, 224, 0) 0%,
          #d9d9d9 100%
        ),
        rgba(255, 255, 255, 0.9);
      box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.9),
        inset 0px 0px 5px rgba(255, 255, 255, 0.8);
      color: #5e5e5e;
      opacity: 1;
      -webkit-transition: box-shadow 0.3s ease;
      transition: box-shadow 0.3s ease;
      max-width: 130px;
      max-height: 80px;
    }
    .dailyRewardButton:hover {
      opacity: 1;
      box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9),
        inset 0px 0px 5px rgba(255, 255, 255, 0.8);
    }
  }
  .quests{
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    input{
      border: none;
      border-radius: 8px;
      font-family: moderat;
      font-size: 12px;
    }
    .instructions{
      font-family: moderat;
      font-size: 12px;
    }
  margin-top: 2rem;
  .questDescription{
    font-size: 12px;
  }
  h2{
    color: white;
  }
  }
  .subscriptionInfo
  {
    font-size: 12px;
      font-family: 'Moderat';
     padding-left: 18px;
  }
  