.signInOut {

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  background-color: #f0f2f5;
}

.login-container {
  width: 100%;
  max-width: 400px;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.displayType {
  color: #555;
  font-size: 14px;
  margin-bottom: 16px;
}
.logo {
  width: 150px;
  margin: 0 auto;
}
.forgotPasswordLink{
  text-align: center;
}
.login-header {
  margin-top: 40px;
  text-align: center;
}

.login-form-container {
  padding: 20px;
  border-radius: 5px;
}

.login-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.login-option {
  width: 48%;
}

.or-divider {
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.or-divider span {
  background-color: #fff;
  padding: 0 10px;
  position: relative;
  z-index: 1;
}

.or-divider::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 1px;
  background-color: #ddd;
  z-index: 0;
}

.input {
  height: 40px;
}

.submit-button {
  width: 100%;
  height: 40px;
}

.forgot-password {
  text-align: center;
}
  .iconButton {
    width: 48px;
    height: 48px;
    border: none;
    .anticon {
      font-size: 24px;
    }
  }

  .inputField {
    border-radius: 8px;
  }

  .submitButton {
    width: 100%;
    border-radius: 8px;
  }
    .modalContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      .ant-form-item {
        margin-bottom: 15px;
      }
      button {
        width: 100%;
        margin-bottom: 10px;
        &:disabled {
          background-color: #ccc;
        }
      }
      .registerLoginLink {
        text-align: center;
        a {
          color: #1890ff;
          cursor: pointer;
        }
      }
      a {
        color: #1890ff;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .iconButton {
    height: 48px;
    border: 1px solid #ccc;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .googleIcon {
    margin-right: 10px;
  }
  
  .inputField {
    border-radius: 8px;
  }
  form{
    display: flex;
    flex-direction: column;
  }
  .submitButton {
    width: 100%;
    border-radius: 8px;
    margin: 0 auto;
  }
  
  .modalContent {
    .ant-form-item {
      margin-bottom: 15px;
    }
    button {
      width: 100%;
      margin-bottom: 10px;
      &:disabled {
        background-color: #ccc;
      }
    }
    .registerLoginLink {
      text-align: center;
      a {
        color: #1890ff;
        cursor: pointer;
      }
    }
    a {
      color: #1890ff;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .logo{
    z-index: 1;
    margin-left: 0.3rem;
  width: 160px;
  }
  .formContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .inputField, .submitButton {
    width: 300px; /* Or any other value in px depending on your layout */
    height: 40px;
  }

  .ctaContainer {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .ctaTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .ctaText {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .ctaBonus {
    color: #0D6EFD;
    font-weight: bold;
  }
  
  .iconBonus {
    margin: 0.2rem;
    width: 24px;
    vertical-align: middle;
  }