.filterBtnContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the buttons */
  margin: auto;
  width: fit-content;
  margin-bottom: 1rem;
  margin: 10px;
  gap: 10px; /* Add space between buttons for better spacing */
  width: 100%; /* Set to 100% for better responsiveness */
}

.filterButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  border-radius: 20px;
  height: 32px;
  border: 2px solid transparent;
  padding: 6px 16px; /* Slightly reduced vertical padding for better height */
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: rgb(37, 27, 124);
  color: white;
  opacity: 0.7;
  text-decoration: none;
  margin: 1px; /* Consistent spacing around buttons */
  
  &:hover, &.active {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.3);
    border-color: #ffffff;
    transform: translateY(-2px); /* Slight lift effect on hover */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
  }
}

@media (max-width: 600px) {
  .filterBtnContainer {
    width: 90%; /* Adjust width for smaller screens */
  }

  .filterButton {
    font-size: 12px; /* Smaller font size for smaller screens */
    padding: 4px 12px; /* Adjusted padding for smaller screens */
    margin: 2px; /* Reduced margin for tighter spacing on small screens */
  }
}

.expandButton {
  background-color: #0d6efd; // Dark background for contrast
  color: #ffffff; // White text for better visibility
  padding: 6px 16px; // Balanced padding for a better look
  font-size: 14px; // Larger font size for readability
  border: 2px solid transparent; // No border for a sleek look
  cursor: pointer; // Cursor pointer for interactivity
  user-select: none; // Prevent text selection
  border-radius: 25px; // Rounded edges for a modern look
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease, box-shadow 0.3s ease, color 0.3s ease; // Smooth transition for interactive effects
  height: 33px; // Adjusted height for consistency
  min-width: 140px; // Adjusted minimum width for better spacing
  text-decoration: none;
  flex-shrink: 0;
  position: relative;
  box-shadow: 0 3px 6px rgba(29, 48, 158, 0.2); // Subtle shadow for depth

  &:hover, &.active {
    background-color: #205987; // Slightly lighter on hover for feedback
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3); // Enhanced shadow on hover
    transform: translateY(-1px); // Slight lift effect on hover
  }

  &.expand:hover {
    color: #007bff; // Blue color for expand text
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.7); // Glowing blue effect
  }

  &.collapse:hover {
    color: #ff073a; // Red color for collapse text
    box-shadow: 0 0 10px rgba(255, 7, 58, 0.7); // Glowing red effect
  }

  @media (max-width: 600px) {
    padding: 6px 12px; // Adjusted padding for smaller screens
    font-size: 12px; // Smaller font size for smaller screens
    height: 32px; // Adjusted height for smaller screens
    min-width: 120px; // Adjusted minimum width for smaller screens
  }
}

.expandIcon {
  margin-right: 8px; // Adjusted space between text and icon
  font-size: 14px; // Adjusted icon size for balance
  transition: transform 0.3s ease; // Smooth transition for icon rotation
}

/* Premium and Emphasized Buttons */
.filterButton.premium {
  background-color: #4b2e83; /* Deep purple background for a fantasy feel */
  color: #ffe259; /* Bright yellow text for contrast */
  position: relative;
  box-shadow: 0 0 10px rgba(255, 204, 0, 0.6); /* Golden glow effect around the button */
  border: 1px solid #ffd700; /* Golden border for a luxurious look */
}

.filterButton.premium1 {
  background-color: #3333e6; /* Deep purple background for a fantasy feel */
  color: #fdfdfd; /* White text for contrast */
  position: relative;
  box-shadow: 0 0 10px rgba(255, 204, 0, 0.6); /* Golden glow effect around the button */
  border: 1px solid #ffffff; /* White border for a clean look */
}

.premium-tag {
  position: absolute;
  top: -10px;
  right: -10px;
  background: linear-gradient(45deg, #ff416c, #ff4b2b); /* Gradient background for a magical effect */
  color: white;
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.7); /* Red glow effect for the tag */
  text-shadow: 0 0 3px #ff0000; /* Text glow for added fantasy effect */
  border: 1px solid #ffd700; /* Golden border to match the button */
}

/* Optional: Animation for a pulsating glow effect */
@keyframes pulseGlow {
  0% {
    box-shadow: 0 0 10px rgba(255, 204, 0, 0.6);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 204, 0, 0.8);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 204, 0, 0.6);
  }
}

.filterButton.premium {
  animation: pulseGlow 2s infinite ease-in-out;
}

.filterButton.emphasized {
  background-color: #2a77a1; /* Different color */
  color: #ffffff;
  border: 1px solid #00bcd4; /* Different border color */
  box-shadow: 0 0 10px rgba(0, 188, 212, 0.6); /* Different glow effect */

  &:hover, &.active {
    background-color: rgba(0, 188, 212, 0.3);
    box-shadow: 0 0 15px rgba(0, 188, 212, 0.8);
  }
}

.filterButton.emphasized1 {
  background-color: #7e00f4; /* Refined purple shade */
  color: #fff;
  border: 1px solid #8257e5; /* Complementary purple border */
  box-shadow: 0 0 10px rgba(76, 0, 255, 0.7); /* Purple glow effect */

  &:hover, &.active {
    background-color: rgba(130, 87, 229, 0.4);
    box-shadow: 0 0 15px rgba(130, 87, 229, 0.9);
  }
}

.filterButton.emphasized2 {
  background-color: #3d9b73; /* Refined green shade */
  color: #fff;
  border: 1px solid #2bb673; /* Complementary green border */
  box-shadow: 0 0 10px rgba(43, 182, 115, 0.7); /* Green glow effect */

  &:hover, &.active {
    background-color: rgba(43, 182, 115, 0.4);
    box-shadow: 0 0 15px rgba(43, 182, 115, 0.9);
  }
}

.filterButton.emphasized3 {
  background-color: #b33a3a; /* Refined red shade */
  color: #fff;
  border: 1px solid #e57373; /* Complementary red border */
  box-shadow: 0 0 10px rgba(229, 115, 115, 0.7); /* Red glow effect */

  &:hover, &.active {
    background-color: rgba(229, 115, 115, 0.4);
    box-shadow: 0 0 15px rgba(229, 115, 115, 0.9);
  }
}
