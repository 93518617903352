@keyframes xpAnimation {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.xpPopup {
  animation: xpAnimation 2s ease-out;
  top: 50%;
  left: 80%;
  width: 80px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5px 10px;
  border-radius: 15px;
  color: #333;
  font-size: 0.8em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 10;
}
.message-icons {
  display: flex;
  align-items: center;
  justify-content: space-between; // This will put space between the main button groups
  width: 150px;
  // Container for thumbs up and down
  .thumbs-container {
    display: flex;
    gap: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    padding: 5px;
  }

  // Common styles for all buttons
  .icon-button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
    padding: 8px;
    border-radius: 20px;
    transition: background-color 0.2s, transform 0.2s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
      transform: scale(1.05);
    }
  }

  // Separate styles for the regenerate button
  .regenerate-button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 18px; // Slightly larger size for the regenerate icon
    width: 60px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    transition: background-color 0.2s, transform 0.2s;
  }
}